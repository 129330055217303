<template>
	<div class="policy-planning">
		<div class="title-card">
			<div class="title-box">
				<div class="title">政策规划</div>
			</div>
			<div class="search-box">
				<div class="search-form">
					<div class="form-item">
						<div class="form-top">
							<div class="form-lable">标题搜索</div>
							<div>
								<el-input v-model="conPO.policyTitle" placeholder="请输入标题" maxlength="200"
									clearable></el-input>
							</div>
							<div class="form-lable lable">发文部门</div>
							<div>
								<el-input v-model="conPO.dispatchDept" placeholder="请输入发文部门" maxlength="200"
									clearable></el-input>
							</div>
							<div class="form-lable lable">发文字号</div>
							<div>
								<el-input v-model="conPO.dispatchWords" placeholder="请输入发文字号" maxlength="200"
									clearable></el-input>
							</div>
							<div class="form-lable lable">地区</div>
							<div>
								<el-cascader :options="rankList" :props="{
									value: 'code',
									label: 'name',
									children: 'childrenList',
									multiple: true,
									checkStrictly: true,
								}" clearable collapse-tags v-model="conPO.rank" :show-all-levels="false"></el-cascader>
							</div>
						</div>
						<div class="form-bottom">
							<div class="form-lable">发文时间</div>
							<div class="date-picker">
								<el-date-picker v-model="conPO.dateStart" type="month" :picker-options="dateStart"
									:default-value="maxDate" value-format="yyyy-MM" placeholder="选择开始日期"></el-date-picker>
							</div>
							<div class="line"></div>
							<div class="date-picker">
								<el-date-picker v-model="conPO.dateEnd" type="month" :picker-options="dateEnd"
									:default-value="maxDate" value-format="yyyy-MM" placeholder="选择结束日期"></el-date-picker>
							</div>
							<div class="form-lable lable">分类</div>
							<div>
								<el-select v-model="conPO.fileType" filterable placeholder="请选择分类" clearable>
									<el-option v-for="item in typeList" :key="item.dataId" :label="item.dictLabel"
										:value="item.dictValue"></el-option>
								</el-select>
							</div>
							<div class="search-button">
								<el-button type="primary" @click="handleQuery">筛选</el-button>
								<el-button @click="resetQuery">清空</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="list-box">
			<div class="result" v-show="total != 0">
				<div class="sort-box">
					<div class="sort">排序</div>
					<div class="sort-select">
						<!-- <el-radio v-model="sortType" @change="relevancyChange" label="relevancy"
              >相关度</el-radio
            > -->
						<el-radio @change="sortTypeChange" v-model="sortType" label="time">
							<span @click="sortTime">
								时间
								<i class="iconfont icon-jiangxu" v-show="sortType == 'time' && timeSort"></i>
								<i class="iconfont icon-shengxu" v-show="sortType == 'time' && !timeSort"></i>
							</span>
						</el-radio>
					</div>
				</div>
				<div></div>
				<div class="result-num">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>
			</div>
			<div class="result-box">
				<div class="empty-box" v-show="total == 0">
					<empty></empty>
				</div>
				<div class="list-item" v-for="(item, index) in pagePolicy" :key="index" @click="toDetail(item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.policyTitle" placement="bottom-start">
							<div class="text-ellipsis">
								{{ item.policyTitle }}
							</div>
						</el-tooltip>
					</div>
					<div class="bottom-box">
						<collect :ab="false" v-model="item.isCollection" :itemId="item.policyPlanId" type='政策规划' />
						<div class="tag-bottom">
							<div class="tag-item" v-for="(key, i) in item.policyTag" :key="i" v-show="i == 0">
								{{ key }}
							</div>
						</div>
						<div class="date">{{ item.dispatchDate }}</div>

					</div>

				</div>
			</div>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0" :key="paginationKey">
			</pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showPayBtn="showPayBtn" :text="text"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
import ToPayOrMember from "@/components/ToPayOrMember"
import collect from "@/components/collect";
export default {
	components: {
		Pagination,
		Empty,
		LoginModal,
		MemberModal,
		ToPayOrMember,
		collect
	},
	data() {
		return {
			text: "",
			showPayBtn: false,
			paginationKey: "",
			total: 0, // 结果数量
			conPO: {
				policyTitle: "",
				dispatchDept: "",
				dispatchWords: "",
				rank: "",
				fileType: '',
				dateStart: "",
				dateEnd: "",
				platform: 1,
				timeSort: 1,
				pageSize: 10, //页数
				pageNum: 1, // 页码
			}, // 查询条件 
			rankList: [], // 级别数据
			typeList: [], // 分类数据
			sortType: "time",
			timeSort: true, //时间排序状态 默认正序
			pagePolicy: [],
			minDate: '',
			maxDate: ''
		};
	},
	computed: {
		dateStart() {
			return {
				disabledDate: time => {
					let disableTime
					if (this.conPO.dateEnd) {
						disableTime = time.getTime() > new Date(this.conPO.dateEnd).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						disableTime = time > this.maxDate || time < this.minDate;
					}
					return disableTime
				},
			};
		},
		dateEnd() {
			return {
				disabledDate: time => {
					if (this.conPO.dateStart) {
						return time.getTime() < new Date(this.conPO.dateStart).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
	},
	mounted() {
		this.getTimeSelector()
		let app = document.getElementById("app");
		app.removeAttribute("class");
		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011011",
		});
		this.getAreaList();
		this.getDatumReportType();
		this.getPolicyPlanPage();
	},
	methods: {
		getTimeSelector() {
			this.$api.industry.getTimeSelectorPolicyPlan().then(res => {
				this.minDate = new Date(res.data.minDateTime + ' 00:00:00');
				this.maxDate = new Date(res.data.maxDateTime + ` 23:59:59`);
			})
		},
		// 省市区获取
		getAreaList() {
			this.$api.successful
				.getAreaListForPolicyPlan()
				.then(res => {
					// 处理级别数据
					let rankList = res.data;
					rankList.forEach(item => {
						item.childrenList.forEach(key => {
							delete key.childrenList;
						});
					});
					this.rankList = rankList;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 列表数据查询
		getPolicyPlanPage() {
			let { conPO } = this;
			let params = JSON.parse(JSON.stringify(conPO));
			if (params.rank || params.rank.length != 0) {
				let province = [];
				let city = [];
				params.rank.forEach(item => {
					if (item.length == 1) {
						province = [...province, ...item];
					} else {
						city = [...city, ...[item[1]]];
					}
				});
				let newProvince = []
				province.map(item => {
					let hasCity=false
					city.map(i =>{
						if(item.slice(0,2)==i.slice(0,2)){
							hasCity=true
						}
					})
					if(!hasCity){
						newProvince.push(item)
					}
				})
				params.provinceCode = newProvince.toString();
				params.cityCode = city.toString();
				delete params.rank;
			}
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getPolicyPlanPage(params)
				.then(res => {
					res.rows.forEach(item => {
						// if (item.dispatchDate) {
						//   item.dispatchDate = item.dispatchDate.slice(0, 7);
						// }
						if (item.policyTag) {
							item.policyTag = item.policyTag.split(",");
						}
					});
					this.pagePolicy = res.rows;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 资料报告分类
		getDatumReportType() {
			this.$api.industry.getFileType().then(res => {
				this.typeList = res.data;
			}).catch(msg => {
				if (msg?.msg) {
					this.$message.error(msg?.msg);
				}
			});
		},
		// 点击筛选
		handleQuery() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.$refs.pagination.page = 1;
				this.conPO.pageNum = 1;
				this.conPO.pageSize = 10;
				this.getPolicyPlanPage();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 点击清空
		resetQuery() {
			this.conPO = {
				policyTitle: "",
				dispatchDept: "",
				dispatchWords: "",
				rank: "",
				dateStart: "",
				dateEnd: "",
				platform: 1,
				pageSize: 10,
				pageNum: 1,
			};
		},
		// 进入详情
		toDetail(item) {
			if (window.getSelection().toString() === "") {
				this.$api.industry.selectPolicyPlanUrlById(item.policyPlanId).then(res => {
					if (!res.data) {
						// 判断用户是否登录
						let url = this.$router.resolve({
							path: `/industry/industry-detail?type=2&id=${item.policyPlanId}`,
						});
						if (this.$session.getUsers()) {
							window.open(url.href, "_blank");
						} else {
							this.$refs.loginModal.visible = true;
						}
					} else {
						this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						}).then(() => {
							window.open(res.data.url, "_blank");
						});
					}
				}).catch(msg => {
					if (msg.code == '10001111') {
						this.$refs.toPayOrMember.openDialog()
						this.text = msg?.msg
					} else {
						this.$message.error(msg?.msg);
					}
				})

			}
		},
		// 分页方法
		paginChange(page, pageSize) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.conPO.pageNum = page;
				this.conPO.pageSize = pageSize;
				this.getPolicyPlanPage();
			} else {
				this.paginationKey = new Date().getTime();
				this.$refs.loginModal.visible = true;
			}
		},
		// 点击相关度
		relevancyChange() {
			delete this.conPO.timeSort;
			this.getPolicyPlanPage();
		},
		// 切换成时间排序
		sortTypeChange() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.timeSort = false;
				this.conPO.timeSort = 2;
				this.getPolicyPlanPage();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 改变时间排序
		sortTime() {
			this.timeSort = !this.timeSort;
			if (this.timeSort) {
				this.conPO.timeSort = 1;
				this.getPolicyPlanPage();
			} else {
				this.conPO.timeSort = 2;
				this.getPolicyPlanPage();
			}
		},
	},
	destroyed() {
		document.getElementById("app").className = "uncopy";
	},
};
</script>

<style lang="less" scope>
@import "./index.less";
</style>
